import React, { useEffect } from "react";
import css from "./Hero.module.scss";
import { AlignOptions } from "../../Elements/AlignOptions/AlignOptions.jsx";
import { setHost } from "../../../util/setHost.js";
import ScrollIndicator from "./ScrollIndicator.jsx";
import Player from '@vimeo/player'

export const VimeoHero = (props) => {
const vimeo_id = props.vimeo_url.split('/').pop();
 const host = setHost();
  const mediaURL = host + props?.backgroundImage?.data?.attributes?.url;
    const headerAlign = AlignOptions({ optionAlign: props?.headerTextAlign });

  function Element({ tag, cssClass, text }) {
    return React.createElement(
      tag?.tag,
      { className: cssClass + " " + tag?.css + " " + css.heading },
      text
    );
  }

    useEffect(() => {
        var video01Player = new Player(`vimeo-${vimeo_id}`, {id: vimeo_id, background:true,loop:true,dnt:true});

  },[vimeo_id])

  return (
    <>
      <div id={`vimeo-${vimeo_id}`} className={`${css.hero} ${css.vimeo_player}  ${props?.vimeoHeight} full`}>
        <div
          className={`${css.content} ${css.vimeo_content} ${props?.vimeoHeight}  ${props?.headerOptAlign} ${props?.headerVertical} ${props?.headerHorizontal}`}
        >

          <Element
            tag={props.headerOptSize}
            cssClass={`${props?.headerOptColor} ${props?.headerHorizontal} ${headerAlign}`}
            text={props?.header}
          />
        </div>
        <div
          className={css.bottomElm}
          style={{
            display:
              props?.headerPlacementVertical === "Bottom" ? "none" : "block",
          }}
        >
          {props?.backgroundSize === "Large" && (
            <>
              <div
                className={css.gradient}
                style={{
                  backgroundImage: `linear-gradient(180deg, transparent, ${props.colorGradient} 100%)`,
                }}
              >
                <ScrollIndicator className={css.scrollIndicator}/>
              </div>
              <div
                className={css.box}
                style={{ backgroundColor: props?.colorGradient }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
